import React, { useState } from "react";
import PropTypes from "prop-types";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./DatePicker.css";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
    set,
    subDays,
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears,
    addWeeks,
} from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { weekString, whichWeek } from "../../redux/action";

const DatePickerF = ({ onChange }) => {
    const dispatch = useDispatch()
    const week = useSelector(state => state.week)
    const changedMonth = useSelector(state => state.changedMonth)
    const bidToFleetWeekValues = useSelector(state => state.bidToFleetWeekValues)
    const verticalOrHorizontal = useSelector(state => state.verticalOrHorizontal)
    const defineds = {
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
        startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
        startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
        startOfYear: startOfYear(new Date()),
        endOfYear: endOfYear(new Date()),
        startOflastYear: startOfYear(addYears(new Date(), -1)),
        endOflastYear: endOfYear(addYears(new Date(), -1)),
        firstDayOfMonth: addDays(startOfWeek(startOfMonth(set(startOfMonth(new Date()), { month: changedMonth }))), 1),
        first7DayOfMonth: addDays(endOfWeek(startOfMonth(set(startOfMonth(new Date()), { month: changedMonth }))), 1),
        secondWeekStartDate: addDays(startOfWeek(addWeeks(startOfMonth(set(startOfMonth(new Date()), { month: changedMonth })), 1)), 1),
        secondWeekEndDate: addDays(endOfWeek(addWeeks(startOfMonth(set(startOfMonth(new Date()), { month: changedMonth })), 1), 1), 1),
        thirdWeekStartDate: addDays(startOfWeek(addWeeks(startOfMonth(set(startOfMonth(new Date()), { month: changedMonth })), 2)), 1),
        thirdWeekEndDate: addDays(endOfWeek(addWeeks(startOfMonth(set(startOfMonth(new Date()), { month: changedMonth })), 2)), 1),
        fourthWeekStartDate: addDays(startOfWeek(addWeeks(startOfMonth(set(startOfMonth(new Date()), { month: changedMonth })), 3)), 1),
        fourthWeekEndDate: addDays(endOfWeek(addWeeks(startOfMonth(set(startOfMonth(new Date()), { month: changedMonth })), 3)), 1),
    };

    const [state, setState] = useState([
        {
            startDate: addDays(startOfWeek(new Date()), 1),
            endDate: addDays(endOfWeek(new Date()), 1),
            key: "selection"
        }
    ]);

    const sideBarOptions = () => {
        const customDateObjects = [
            {
                label: bidToFleetWeekValues[0],
                range: () => ({
                    startDate: bidToFleetWeekValues[0] == "Indiki həftə" ? addDays(startOfWeek(new Date()), 1) : defineds.firstDayOfMonth,
                    endDate: bidToFleetWeekValues[0] == "Indiki həftə" ? addDays(endOfWeek(new Date()), 1) : defineds.first7DayOfMonth
                }),
            },
            {
                label: bidToFleetWeekValues[1],
                range: () => ({
                    startDate: bidToFleetWeekValues[1] == "1 həftə əvvəl" ? addDays(startOfWeek(new Date()), -6) : defineds.secondWeekStartDate,
                    endDate: bidToFleetWeekValues[1] == "1 həftə əvvəl" ? addDays(endOfWeek(new Date()), -6) : defineds.secondWeekEndDate
                }),
            },
            {
                label: bidToFleetWeekValues[2],
                range: () => ({
                    startDate: bidToFleetWeekValues[2] == "2 həftə əvvəl" ? addDays(startOfWeek(new Date()), -13) : defineds.thirdWeekStartDate,
                    endDate: bidToFleetWeekValues[2] == "2 həftə əvvəl" ? addDays(endOfWeek(new Date()), -13) : defineds.thirdWeekEndDate
                }),
            },
            {
                label: bidToFleetWeekValues[3],
                range: () => ({
                    startDate: bidToFleetWeekValues[3] == "3 həftə əvvəl" ? addDays(startOfWeek(new Date()), -20) : defineds.fourthWeekStartDate,
                    endDate: bidToFleetWeekValues[3] == "3 həftə əvvəl" ? addDays(endOfWeek(new Date()), -20) : defineds.fourthWeekEndDate
                }),
            },
        ];
        return customDateObjects;
    };

    const rangeBtns = document.querySelectorAll(".rdrStaticRangeLabel")
    rangeBtns.forEach((el) => {
        el.addEventListener("click", (e) => {
            dispatch(weekString(e.target.innerHTML))
            if (e.target.innerHTML === "Indiki həftə" || e.target.innerHTML === "1-ci həftə") {
                dispatch(whichWeek(1))
            }
            else if (e.target.innerHTML === "1 həftə əvvəl" || e.target.innerHTML === "2-ci həftə") {
                dispatch(whichWeek(2))
            }
            else if (e.target.innerHTML === "2 həftə əvvəl" || e.target.innerHTML === "3-cü həftə") {
                dispatch(whichWeek(3))
            }
            else if (e.target.innerHTML === "3 həftə əvvəl" || e.target.innerHTML === "4-cü həftə") {
                dispatch(whichWeek(4))
            }
        });
    })
    const sideBar = sideBarOptions();
    const staticRanges = [
        ...createStaticRanges(sideBar)
    ];

    const handleOnChange = ranges => {
        const { selection } = ranges;
        onChange(selection);
        setState([selection]);
    };

    return (
        <DateRangePicker
            onChange={(e)=>handleOnChange(e)}
            rangeColors={'#6F52EA'}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            staticRanges={staticRanges}
            direction={verticalOrHorizontal}
            inputRanges={[]}
            weekStartsOn={1}
        />
    );
};

DatePickerF.propTypes = {
    onChange: PropTypes.func
};

export default DatePickerF;
