import React, { useEffect, useState } from 'react';
import './Map.css';
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
import Input from '../../components/Input/Input';
import Dropdown from '../../components/Dropdown/Dropdown';
import { FaSortDown, FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { getDriverIdsAction, getMarkerDetailsAction, searchAction, searchBid } from '../../redux/action';

const Map = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("Sürücü");
  const [detail, setDetail] = useState(null);
  const [placeholder, setPlaceholder] = useState('Sürücü');
  const center = useMemo(() => ({ lat: 40.40597944516538, lng: 49.86318468461669 }), []);
  const customMarker = useSelector(state => state.customMarker)
  const mapOptins = useSelector(state => state.mapOptins)
  const markers = useSelector(state => state.markers)
  const dispatch = useDispatch()
  const markers2 = useSelector(state => state.markers2)
  const markers2Detils = useSelector(state => state.markerDetails)
  const [driverOrMobileValue, setDriverOrMobileValue] = useState("")
  const searchValue = useSelector(state => state.searchValue)
  useEffect(() => {
    dispatch(getDriverIdsAction(localStorage.getItem("fleetId")))
  }, [])
  const changeDriverOrMobileValue = async (e) => {
    if (selected === "Sürücü") {
      if (e.target.value.length > 1) {
        setDriverOrMobileValue(e.target.value)
        return dispatch(searchAction(0, e.target.value))
      }
      return setDriverOrMobileValue(e.target.value)
    }
    if (e.target.value.length == 17) {
      handlePhoneChange(e)
      return dispatch(searchAction(1, e.target.value.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")))
    }
    else if (e.target.value.length < 17) {
      return handlePhoneChange(e)
    }
  }
  const handlePhoneChange = (e) => {
    if (e.target.value?.length < 18 && !isNaN(e.target.value.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', ''))) {
      var len = e.target.value?.length;
      if (e.target.value?.slice(0, 5) != "+994(" && e.target.value?.slice(0, 4) != "+994" && e.target.value?.slice(0, 3) != "+99" && e.target.value?.slice(0, 2) != "+9" && e.target.value?.slice(0, 1) != "+") {
        setDriverOrMobileValue("+994(" + e.target.value);
      }
      else if (len === 4 && (!driverOrMobileValue.endsWith('-') && !driverOrMobileValue.endsWith('(') && !driverOrMobileValue.endsWith(')'))) {
        setDriverOrMobileValue(e.target.value + "(");
      }
      else if (len === 7 && (!driverOrMobileValue.endsWith('-') && !driverOrMobileValue.endsWith('(') && !driverOrMobileValue.endsWith(')'))) {
        setDriverOrMobileValue(e.target.value + ")");
      }
      else if ((len === 11 || len === 14) && (!driverOrMobileValue.endsWith('-') && !driverOrMobileValue.endsWith('(') && !driverOrMobileValue.endsWith(')'))) {
        setDriverOrMobileValue(e.target.value + "-");
      }
      else if (len < 18) {
        setDriverOrMobileValue(e.target.value);
      }
    }
  };
  const handleOpen = () => {
    setOpen(!open);
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBx3uoJ51lGTzV-pm1GBKDfZUIwoZw2cqI',
  });
  return (
    <div className="App">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          options={{ disableDefaultUI: true, styles: mapOptins }}
          zoom={13}
        >
          {/* {markers.map((taxi) => <MarkerF key={taxi.id} position={taxi.position} icon={customMarker} onClick={() => setDetail(taxi.id)} />)} */}
          {
            markers2.map((taxi) => <MarkerF key={taxi._id} position={{ lat: taxi.location.coordinates[1], lng: taxi.location.coordinates[0] }} icon={customMarker} onClick={() => { setDetail(taxi.token); dispatch(getMarkerDetailsAction(taxi.token)) }} />)
          }
        </GoogleMap>
      )}
      <div className='map-content'>
        <div className='map-options'>
          <Dropdown
            open={open}
            trigger={<button onClick={handleOpen}>{selected}<FaSortDown /></button>}
            menu={[
              <button onClick={() => { handleOpen(); setPlaceholder('Sürücü'); setSelected('Sürücü'); setDriverOrMobileValue(""); dispatch(searchBid(null)); setDetail(null) }}>Sürücü</button>,
              // <button onClick={() => { handleOpen(); setPlaceholder('Nömrə nişanı'); setSelected('Nömrə nişanı') }}>Nömrə nişanı</button>,
              <button onClick={() => { handleOpen(); setPlaceholder('Telefon nömrəsi'); setSelected('Telefon nömrəsi'); setDriverOrMobileValue(""); dispatch(searchBid(null)); setDetail(null) }}>Telefon nömrəsi</button>,
            ]}
          />
          <Input placeholder={placeholder} value={driverOrMobileValue} onchange={e => changeDriverOrMobileValue(e)} />
        </div>
        {detail !== null &&
          <div className='map-details'>
            <div className='flex'>
              <img className='image' height="40px" width="40px" alt={markers2Detils?.driver?.name + " " + markers2Detils?.driver?.lastname} src={markers2Detils?.driver?.image ? markers2Detils?.driver?.image : "assets/img/default_image.png"} />
              <div>{markers2Detils?.driver?.name + " " + markers2Detils?.driver?.lastname} {markers2Detils?.driver?.rate.toFixed(2)} <FaStar size={12} /></div>
            </div>
            <hr></hr>
            <div>Gündəlik səfərlər: {Array.isArray(markers2Detils?.stat) ? markers2Detils?.stat[0]?.count : "0"} </div>
            <div>Gündəlik qazanc: {Array.isArray(markers2Detils?.stat) ? markers2Detils?.stat[0]?.sum : "0.00"} ₼ </div>
          </div>
        }
        {(searchValue !== null && searchValue.length != 0) ?
          <div className='map-details'>
            <div className='flex search'>
              <img className='image' height="40px" width="40px" alt={searchValue.name + " " + searchValue.lastname} src={searchValue.image ? searchValue.image : "assets/img/default_image.png"} />
              <div>{searchValue.name + " " + searchValue.lastname} {searchValue.rate?.toFixed(2)} <FaStar size={12} /></div>
            </div>
            <hr />
            <div>Gündəlik səfərlər: {searchValue.tripCount ? searchValue.tripCount : "0"} </div>
            <div>Gündəlik qazanc: {searchValue.balance ? searchValue.balance.toFixed(2) : "0.00"} ₼ </div>
            <hr />
          </div>
          : <></>}
      </div>
    </div >
  )
};

export default Map;