import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
// background: #f1f1f1;
// box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px;
height: 85px;
display: flex;
justify-content: space-between;
// padding: 0.2rem calc((100vw - 800px) / 2);
z-index: 12;
/* Third Nav */
/* justify-content: flex-start; */
`;

export const NavLink = styled(Link)`
color: #858585;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
width: 70px;
margin: 10px;
font-size: 18px;
font-weight: 600;
background-color: white;
&.active {
	color: #000000;    
    text-decoration: underline;
    text-underline-offset: 10px;
}
&.logout {
	color: #6F52EA !important;    
    text-decoration: underline;
    text-underline-offset: 10px;
}
&:hover {
	transition: all 0.2s ease-in-out;
	color: #000;
    text-decoration: underline;
    text-underline-offset: 10px;
}
`;

export const Bars = styled(FaBars)`
display: none;
color: #808080;
@media screen and (max-width: 768px) {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-100%, 75%);
	font-size: 1.8rem;
	cursor: pointer;
}
`;

export const NavMenu = styled.div`
display: flex;
align-items: center;
margin-right: -24px;
white-space: nowrap; 
width:100%;
justify-content: center;
@media screen and (max-width: 768px) {
	display: none;
	flex-direction: column !important;
}
`;
export const NavMenu2 = styled.div`
display: flex;
align-items: flex-end;
margin: 65px 15px;
white-space: nowrap; 
width:100%;
height: 100px;
justify-content: flex-start;
flex-direction: column !important;
// gap: 25px;
animation: text 0.5s;
backgound-color: white !important;
z-index: 15;

@keyframes text {
    0% {
        transform: translateY(-100px);
    }

    100% {
        transform: translateY(0px);
    }
}
@media screen and (min-width: 768px) {
	display: none;
}
`;

export const NavBtn = styled.nav`
display: flex;
align-items: center;
margin-right: 24px;
width: 100vw; */
@media screen and (max-width: 768px) {
	display: none;
}
`;

export const NavBtnLink = styled(Link)`
border-radius: 4px;
background: #808080;
padding: 10px 22px;
color: #000000;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
/* Second Nav */
margin-left: 24px;
&:hover {
	transition: all 0.2s ease-in-out;
	background: #000;
	color: #808080;
}
`;
