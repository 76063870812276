import React from 'react';
import {
    Nav,
    NavMenu,
} from './NavbarElements';
import { useDispatch, useSelector } from 'react-redux';
import { fleetToBid } from '../../redux/action';
import Button from '../Button/Button';

const Navbar = () => {
    const dispatch = useDispatch()
    const FleetToDriver = useSelector(state => state.FleetToDriver)
    return (
        <>
            <Nav>
                <NavMenu>
                    <Button text="Fleet to driver" black isActive={FleetToDriver} click={e => dispatch(fleetToBid(true))}></Button>
                    <Button text="Bid to fleet" black isActive={!FleetToDriver} click={e => dispatch(fleetToBid(false))}></Button>
                </NavMenu>
            </Nav>
        </>
    );
};

export default Navbar;
