import React, { useEffect, useState } from 'react';
import './Payment.css';
import DriverBox from '../../components/DriverBox/DriverBox';
import DatePicker from '../../components/DateRange/DatePicker';
import { FaCalendarAlt } from "react-icons/fa";
import Input from '../../components/Input/Input';
import { format, startOfWeek, endOfWeek, set, startOfMonth, addDays } from "date-fns";
import Button from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/PaymentNav/Navbar';
import { FaTimes, FaSortDown } from "react-icons/fa";
import { bidToFleetWeekValuesBid, changeMonthBid, exportExcelAction, exportExcelFleetAction, getPaymentsAction, getPaymentsFleetAction } from '../../redux/action';
import DatePickerF from '../../components/FleetDatePicker/DatePicker';
import Dropdown from '../../components/Dropdown/Dropdown';
const Payment = () => {
  const dispatch = useDispatch()
  const weekString = useSelector(state => state.week_string)
  const bidToFleetWeekValues = useSelector(state => state.bidToFleetWeekValues)
  const [startDate, setStartDate] = useState(format(addDays(startOfWeek(new Date()), 1), "dd.MM.yyyy"));
  const [endDate, setEndDate] = useState(format(addDays(endOfWeek(new Date()), 1), "dd.MM.yyyy"));
  const [staticEndDate,] = useState(format(endOfWeek(new Date()), "dd.MM.yyyy"));
  const [staticStartDate,] = useState(format(startOfWeek(new Date()), "dd.MM.yyyy"));
  const week = useSelector(state => state.week)
  const month = useSelector(state => state.month)
  const [changeMonth, setChangeMonth] = useState(format(new Date(), "dd.MM.yyyy").split(".")[1] - 1)
  const fleetPayment = useSelector(state => state.fleetPayment)
  const [monthDrop, setMonthDrop] = useState(false)
  const weekStringDiv = document.querySelector(".rdrStaticRangeSelected")?.querySelector("span").innerHTML
  useEffect(() => {
    dispatch(getPaymentsAction(startDate, endDate))
  }, [startDate, endDate])
  useEffect(() => {
    dispatch(getPaymentsFleetAction(changeMonth, week))
  }, [changeMonth, week])
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const paymentList = useSelector(state => state.paymentList)
  const FleetToDriver = useSelector(state => state.FleetToDriver)
  const onChange = (ranges) => {
    setStartDate(format(new Date(ranges.startDate), "dd.MM.yyyy"));
    setEndDate(format(new Date(ranges.endDate), "dd.MM.yyyy"));
  };
  useEffect(() => {
    if (changeMonth + 1 != format(new Date(), "dd.MM.yyyy").split(".")[1]) {
      dispatch(bidToFleetWeekValuesBid(["1-ci həftə", "2-ci həftə", "3-cü həftə", "4-cü həftə"]))
    }
    else {
      dispatch(bidToFleetWeekValuesBid(["Indiki həftə", "1 həftə əvvəl", "2 həftə əvvəl", "3 həftə əvvəl"]))
    }
  }, [changeMonth])

  return (
    <div className='container'>
      <Navbar />
      {FleetToDriver ? <>
        <div className='flex spacebetween'>
          <div className='date-container'>
            <FaCalendarAlt size={50} color='#6F52EA' onClick={() => toggle()} />
            <Input read small placeholder={startDate}></Input>
            <Input read small placeholder={endDate}></Input>
          </div>
          <Button text="Excel yükləyin" click={e => dispatch(exportExcelAction(startDate, endDate, paymentList))}></Button>
        </div>
        <div className={!isOpen && 'hidden'}>
          <DatePicker onChange={onChange}></DatePicker>
          {/* <Button text="Tarixləri Seçin"></Button> */}
        </div>
        <div className='payment-container'>
          {
            paymentList.map((box, index) => < DriverBox box={box} key={index} />)
          }
        </div>
      </> :
        <>
          <div className='paymentModal-container'>
            <div className='paymentModal-containerTop'>
              <div className='date-container'>
                <FaCalendarAlt size={50} color='#6F52EA' onClick={() => toggle()} />
                <Input read small placeholder={startDate}></Input>
                <Input read small placeholder={endDate}></Input>
              </div>
              {isOpen && <div className='paymentModal-containerCalendar'>
                <Dropdown
                  open={monthDrop}
                  trigger={<button onClick={e => setMonthDrop(!monthDrop)}>{month[changeMonth]}<FaSortDown /></button>}
                  menu={
                    month.map((mnth, index) => {
                      if (index < parseInt(format(new Date(), "dd.MM.yyyy").split(".")[1])) {
                        return <button onClick={() => {
                          const selectedDate = set(startOfMonth(new Date()), { month: index });
                          setMonthDrop(!monthDrop);
                          setChangeMonth(index);
                          dispatch(changeMonthBid(index))
                          setStartDate(format(addDays(startOfWeek(selectedDate), 1), 'dd.MM.yyyy'));
                          setEndDate(format(addDays(endOfWeek(selectedDate), 1), 'dd.MM.yyyy'));
                        }}>{mnth}</button>
                      }
                    }
                    )
                  }
                />
                <DatePickerF onChange={onChange}></DatePickerF>
              </div>}
            </div>
            <div className='modal'>
              <div className='modal-header'>
                <h3>Tarix</h3>
              </div>
              <div className='modal-header-rows'>
                <span className='modal-header-row'>Kart<span>--- {fleetPayment?.commission} &#8380;</span></span>
                <span className='modal-header-row'>Kamisiya (xidmət haqqı)<span>{fleetPayment?.commission} &#8380;</span></span>
                <span className='modal-header-row'>Bonus<span>{fleetPayment?.bonus} &#8380;</span></span>
                <span className='modal-header-row'>Vergi (%2)<span>--- {fleetPayment?.commission} &#8380;</span></span>
              </div>
              <hr />
              <div className='modal-content'>
                <span className='modal-header-row '><b>Brüt qacanc</b><span><b>{fleetPayment?.fee} &#8380;</b></span></span>
              </div>
            </div>
            {
              !(weekString === "Indiki həftə" &&
                (weekStringDiv === "Indiki həftə" ||
                  weekStringDiv === undefined ||
                  weekStringDiv === "Son 7 Gün")) ?
                <Button text="excel yükləyin" click={e => dispatch(exportExcelFleetAction(startDate, endDate, fleetPayment))}></Button> : <></>
            }
          </div>
        </>
      }
    </div>
  )
};

export default Payment;