import React, { useState, useEffect } from 'react';
import './Drivers.css';
import DataTable from 'react-data-table-component';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { FaTimes, FaSortDown } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../components/Dropdown/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cars from "../../data/carsData.json"
import carColors from "../../data/carsColor.json"
import carSeats from "../../data/carSeats.json"
import { BiImageAdd } from 'react-icons/bi';
import { blockDriverAction, checkPhoneNumberAction, checkPhoneNumberBid, deleteAction, deleteDriverAction, driverRegistrationAction, editDriverAction, getDriversAction, getInvitesAction, setTariffAction, checkActiveDriversAction } from '../../redux/action';
import { setYear } from 'date-fns';

const Drivers = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const columns = [
    {
      name: 'Sürücü',
      selector: row => row.driver?.name,
      sortable: true,
      compact: true,
      center: true,
      cell: (row) => (
        <div className='cell-item'><span>{row.driver?.name + " " + row.driver?.lastname}</span></div>
      )
    },
    {
      name: 'Telefon nömrəsi',
      selector: row => row?.driver?.mobileNum,
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'Avtomobil',
      selector: row => row?.driver?.car?.maker + " " + row?.driver?.car?.model,
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'Plaka',
      selector: row => row?.driver?.car?.plateNumber,
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'IDCard',
      selector: row => row?.driver?.IDCard,
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'Balance',
      selector: row => row?.driver?.balance?.toFixed(2),
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'Səfər sayı',
      selector: row => row?.driver?.tripCount,
      sortable: true,
      center: true,
      compact: true
    },
    // {
    //   name: '',
    //   // selector: row => row.docs,
    //   sortable: true,
    //   compact: true,
    //   center: true,
    //   cell: (row) => (
    //     <div className="cell-item">
    //       {/* <Button gray small rounded text={"Tarif"} click={(e) => { setFeeId(row?.driverId); setFeeVisible(true) }}></Button> */}
    //       <Button gray small rounded text={"Redaktə"} click={() => { setFeeId(row?.driverId); setEditVisible(true) }}></Button>
    //     </div>
    //   )
    // },
    {
      name: '',
      // selector: row => row.docs,
      sortable: true,
      compact: true,
      center: true,
      cell: (row) => (
        <div className="cell-item">
          <Button red small rounded text={"Sil"} click={() => { setFeeId(row?.driverId); setBlockVisible(true); }}></Button>
        </div>
      )
    },
  ];
  const columns2 = [
    {
      name: 'Sürücü',
      selector: row => row.driver?.name,
      sortable: true,
      compact: true,
      center: true,
      cell: (row) => (
        <div className='cell-item'><span>{row.name + " " + row.lastname}</span></div>
      )
    },
    {
      name: 'Telefon nömrəsi',
      selector: row => row.mobileNum,
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'Avtomobil',
      selector: row => row.car?.maker + " " + row.car?.model,
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'Plaka',
      selector: row => row.car?.plateNumber,
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'IDCard',
      selector: row => row.IDCard,
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'Balance',
      selector: row => row.balance?.toFixed(2),
      sortable: true,
      center: true,
      compact: true
    },
    {
      name: 'Səfər sayı',
      selector: row => row?.tripCount,
      sortable: true,
      center: true,
      compact: true
    }
  ];
  // const columns2 = useSelector(state => state.columns2)
  const customStyles = useSelector(state => state.customStyles)
  const data = useSelector(state => state.data)
  const data2 = useSelector(state => state.data2)
  const checkPhoneNum = useSelector(state => state.checkPhoneNum)
  const activeDrivers = useSelector(state => state.activeDrivers)
  const [tab, setTab] = useState("Dəvətlər")
  const [visible, setVisible] = useState(false)
  const [feeVisible, setFeeVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [blockVisible, setBlockVisible] = useState(false)
  const [feeId, setFeeId] = useState(0)
  const [driver, setDriver] = useState(false)
  const [open, setOpen] = useState(false)
  const [openAutoBrends, setOpenAutoBrends] = useState(false)
  const [openAutoModel, setOpenAutoModel] = useState(false)
  const [openAutoBrendsColor, setOpenAutoBrendsColor] = useState(false)
  const [openSeatsCount, setOpenSeatsCount] = useState(false)
  const [openAutoYear, setOpenAutoYear] = useState(false)
  const [carType, setCarType] = useState('Eco')
  const [selectedImage, setSelectedImage] = useState(null);
  const [gender, setGender] = useState("Cins")
  const [avtoBrend, setAutoBrend] = useState("")
  const [autoModel, setAutoModel] = useState("")
  const [avtoBrendColor, setAutoBrendColor] = useState("")
  const [seatsCount, setSeatsCount] = useState("Oturacaqların sayı")
  const [autoYear, setAutoYear] = useState("")
  const [phoneNum, setPhoneNum] = useState('');
  const [carNumber, setCarNumber] = useState('');
  const [startDate, setStartDate] = useState(false);
  const [name, setName] = useState("")
  const [lastname, setLastname] = useState("")
  const [mail, setMail] = useState("")
  const [baseFare, setBaseFare] = useState("")
  const [costPerMin, setCostPerMin] = useState("")
  const [costPerKm, setCostPerKm] = useState("")
  const [carTypeId, setCarTypeId] = useState(0)
  const [cardNo, setCardNo] = useState("")
  const [accountNo, setAccountNo] = useState("")
  const [reason, setReason] = useState("")
  const [id, setId] = useState("")
  useEffect(() => {
    dispatch(getDriversAction(localStorage.getItem("fleetId")))
  }, [userInfo, feeVisible, editVisible, blockVisible])
  useEffect(() => {
    dispatch(getInvitesAction(localStorage.getItem("fleetId")))
  }, [userInfo])
  useEffect(() => {
    dispatch(checkActiveDriversAction(localStorage.getItem("fleetId")))
  }, [userInfo])
  useEffect(() => {
    if (checkPhoneNum?.id) {
      setName(checkPhoneNum.name)
      setLastname(checkPhoneNum.lastname)
      setGender(checkPhoneNum.gender ? "Kişi" : "Qadın")
      setStartDate(new Date(checkPhoneNum.birthDate))
      setPhoneNum(checkPhoneNum.mobileNum)
      setId(checkPhoneNum.IDCard)
      setCarNumber(checkPhoneNum.car.plateNumber)
      setAutoBrend(checkPhoneNum.car.maker)
      setAutoModel(checkPhoneNum.car.model)
      setAutoYear(checkPhoneNum.car.year)
      setAutoBrendColor(checkPhoneNum.car.color)
    }
  }, [checkPhoneNum])
  const navigate = useNavigate();
  const onSetDriver = row => {
    setDriver(row)
    return (
      navigate(`/driver_detail/${row.driverId}`, { state: { row } })
    )
  }
  const handlePhoneChange = (e) => {
    if (e.target.value?.length < 18 && !isNaN(e.target.value.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', ''))) {
      var len = e.target.value?.length;
      if (e.target.value?.slice(0, 5) != "+994(" && e.target.value?.slice(0, 4) != "+994" && e.target.value?.slice(0, 3) != "+99" && e.target.value?.slice(0, 2) != "+9" && e.target.value?.slice(0, 1) != "+") {
        setPhoneNum("+994(" + e.target.value);
      }
      else if (len === 4 && (!phoneNum.endsWith('-') && !phoneNum.endsWith('(') && !phoneNum.endsWith(')'))) {
        setPhoneNum(e.target.value + "(");
      }
      else if (len === 7 && (!phoneNum.endsWith('-') && !phoneNum.endsWith('(') && !phoneNum.endsWith(')'))) {
        setPhoneNum(e.target.value + ")");
      }
      else if ((len === 11 || len === 14) && (!phoneNum.endsWith('-') && !phoneNum.endsWith('(') && !phoneNum.endsWith(')'))) {
        setPhoneNum(e.target.value + "-");
      }
      else if (len < 18) {
        setPhoneNum(e.target.value);
      }
    }
  };
  const handleCarNumberChange = (e) => {
    if (e.target.value?.length < 10) {
      var len = e.target.value?.length;
      if (len === 1 && !isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(e.target.value);
      }
      else if (len === 1 && isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(carNumber);
      }
      else if (len === 2 && !carNumber.endsWith('-') && !isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(e.target.value + "-");
      }
      else if (len === 2 && !carNumber.endsWith('-') && isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(carNumber);
      }
      else if (len === 4 && isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(e.target.value.toUpperCase());
      }
      else if (len === 4 && !isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(carNumber);
      }
      else if (len === 5 && !carNumber.endsWith('-') && isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(e.target.value.toUpperCase() + "-");
      }
      else if (len === 5 && !carNumber.endsWith('-') && !isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(carNumber);
      }
      else if (len === 6 && !isNaN(parseInt(e.target.value[len - 1].replace("-", "1"))) && !carNumber.endsWith('-')) {
        setCarNumber(e.target.value);
      }
      else if (len === 6 && isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(carNumber);
      }
      else if (len === 7 && !isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(e.target.value);
      }
      else if (len === 7 && isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(carNumber);
      }
      else if (len === 8 && !isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(e.target.value);
      }
      else if (len === 8 && isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(carNumber);
      }
      else if (len === 9 && !isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(e.target.value);
      }
      else if (len === 9 && isNaN(parseInt(e.target.value[len - 1]))) {
        setCarNumber(carNumber);
      }
      else {
        setCarNumber(e.target.value);
      }
    }
  }
  const showAllParameters = () => {

    dispatch(driverRegistrationAction(name, lastname, gender, phoneNum, avtoBrend, startDate, autoModel, avtoBrendColor, carNumber, autoYear, id))
    setVisible(false)
    dispatch(checkPhoneNumberBid(false))
    setName("")
    setLastname("")
    setGender("Cins")
    setPhoneNum("")
    setAutoBrend("")
    setAutoModel("")
    setAutoBrendColor("")
    setCarNumber("")
    setAutoYear("")
    setId("")
    setStartDate("")
  }

  return (
    <div className='container'>
      <div className='page-header'>
        <h2>Sürücülər</h2>
        <Button text='Yeni sürücü' add click={() => setVisible(true)}></Button>
      </div>
      <div className='onlineContainer'>
        Online Sürücü: {activeDrivers}
      </div>
      <div className='table-container'>
        <div className='tab-container'>
          <div className={tab === 'Dəvətlər' ? "tab line" : "tab"} onClick={() => setTab("Dəvətlər")}>
            Onlayn Sürücülər
          </div>
          <div className={tab === 'Hamısı' ? "tab line" : "tab"} onClick={() => setTab("Hamısı")}>
            Hamısı
          </div>

        </div>
        {tab === 'Hamısı' ?
          <DataTable
            columns={columns}
            data={data}
            pagination
            highlightOnHover={true}
            customStyles={customStyles}
            onRowClicked={onSetDriver}
          />
          :
          <DataTable
            columns={columns2}
            data={data2}
            pagination
            highlightOnHover={true}
            customStyles={customStyles}
          />
        }
      </div>
      <div className={feeVisible ? 'modal-container' : 'hidden'}>
        <div className='modal'>
          <div className='modal-header'>
            <h3>Tarifi təyin edin</h3>
            <FaTimes onClick={() => setFeeVisible(false)} />
          </div>
          <div className='head'>
            <img className='image' height="40px" width="40px" src={data.find((user) => user?.driverId === feeId)?.image ? data.find((user) => user?.driverId === feeId)?.image : "assets/img/default_image.png"} alt='-' />
            <label>{data?.find((user) => user?.driverId === feeId)?.driver?.name + " " + data?.find((user) => user?.driverId === feeId)?.driver?.lastname}</label>
          </div>
          <hr />
          <div className='modal-content'>
            <Input placeholder='Əsas tarif' value={baseFare} onchange={e => setBaseFare(e.target.value)} />
            <Input placeholder='Qiymət / dəqiqə' value={costPerMin} onchange={e => setCostPerMin(e.target.value)} />
            <Input placeholder='Qiymət / kilometr' value={costPerKm} onchange={e => setCostPerKm(e.target.value)} />
            <Button text='Göndər' click={e => { dispatch(setTariffAction(feeId, baseFare, costPerMin, costPerKm)); setFeeVisible(false) }}></Button>
          </div>
        </div>
      </div>
      <div className={editVisible ? 'modal-container' : 'hidden'}>
        <div className='modal'>
          <div className='modal-header'>
            <h3>Məlumatı yeniləyin</h3>
            <FaTimes onClick={() => setEditVisible(false)} />
          </div>
          <div className='head'>
            <img className='image' height="40px" width="40px" src={data.find((user) => user?.driverId === feeId)?.image ? data.find((user) => user?.driverId === feeId)?.image : "assets/img/default_image.png"} alt='-' />
            <label>{data?.find((user) => user?.driverId === feeId)?.driver?.name + " " + data?.find((user) => user?.driverId === feeId)?.driver?.lastname}</label>
          </div>
          <hr />
          <div className='modal-content'>
            <Input placeholder='Kart nömrəsi' value={cardNo} onchange={e => setCardNo(e.target.value)} />
            <Input placeholder='Hesab nömrəsi' value={accountNo} onchange={e => setAccountNo(e.target.value)} />
            <Dropdown
              open={open}
              trigger={<button onClick={() => setOpen(!open)}>{carType}<FaSortDown /></button>}
              menu={[
                <button onClick={() => { setOpen(!open); setCarType('Eco'); setCarTypeId(0) }}>Eco</button>,
                <button onClick={() => { setOpen(!open); setCarType('Comfort'); setCarTypeId(1) }}>Comfort</button>,
                <button onClick={() => { setOpen(!open); setCarType('XL'); setCarTypeId(2) }}>XL</button>,
              ]}
            />
            <Button text='Göndər' click={e => { dispatch(editDriverAction(feeId, cardNo, accountNo, carTypeId)); setEditVisible(false) }}></Button>
            <Button red text='Sürücünü fleetten çıxarın' click={e => { dispatch(deleteDriverAction(data?.find((user) => user?.driverId === feeId)?.driver?.id)) }}></Button>
          </div>
        </div>
      </div>
      <div className={blockVisible ? 'modal-container' : 'hidden'}>
        <div className='modal'>
          <div className='modal-header'>
            <h3>Sürücünü fleetten çıxarın</h3>
            <FaTimes onClick={() => { setBlockVisible(false); }} />
          </div>
          <hr />
          <div className='modal-content'>
            Sürücü silinəcək, təsdiq edirsiniz?
            <Button text='OK' click={e => { dispatch(deleteDriverAction(data?.find((user) => user?.driverId === feeId)?.driver?.id)); setBlockVisible(false); }}></Button>
          </div>
        </div>
      </div>
      <div className={visible ? 'modal-container' : 'hidden'}>
        {!checkPhoneNum && <div className='modal'>
          <div className='modal-header'>
            <h3>Sürücü ekleyin</h3>
            <FaTimes onClick={() => { setVisible(false); dispatch(checkPhoneNumberBid(false)) }} />
          </div>
          <div className='modal-content'>
            <Input placeholder='Telefon nömrəsi (+994XX-XXX-XX-XX)' value={phoneNum} onchange={e => handlePhoneChange(e)} />
            <Button text='Göndər' click={() => dispatch(checkPhoneNumberAction(phoneNum))}></Button>
          </div>
        </div>}

        {checkPhoneNum && <div className='modal addNewTaxi'>
          <div className='modal-header'>
            <h3>Sürücü ekleyin</h3>
            <FaTimes onClick={() => { setVisible(false); dispatch(checkPhoneNumberBid(false)) }} />
          </div>
          <div className='modal-content'>
            <Input placeholder='Ad' value={name} onchange={e => setName(e.target.value)} />
            <Input placeholder='Soyad' value={lastname} onchange={e => setLastname(e.target.value)} />
            <Dropdown
              open={open}
              trigger={
                <button
                  onClick={() => {
                    setOpen(!open);
                    setOpenAutoBrends(false);
                    setOpenAutoModel(false);
                    setOpenAutoBrendsColor(false);
                    setOpenAutoModel(false);
                    setOpenAutoBrendsColor(false);
                    setOpenSeatsCount(false);
                    setOpenAutoYear(false)
                  }}>
                  {gender}<FaSortDown /></button>
              }
              menu={[
                <button onClick={() => { setOpen(!open); setGender('Kişi') }}>Kişi</button>,
                <button onClick={() => { setOpen(!open); setGender('Qadın') }}>Qadın</button>,
              ]}
            />
            <div className='input'>
              <DatePicker
                placeholderText='Doğum tarixi'
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                icon="fa fa-calendar"
                dateFormat='dd-MM-yyyy'
                showYearDropdown
                showMonthDropdown
              />
            </div>
            <Input placeholder='Telefon nömrəsi (+994XX-XXX-XX-XX)' onchange={e => handlePhoneChange(e)} value={phoneNum} />
            {/* <Dropdown
              open={openAutoBrends}
              trigger={
                <button
                  onClick={() => {
                    setOpen(false);
                    setOpenAutoBrends(!openAutoBrends);
                    setOpenAutoModel(false);
                    setOpenAutoBrendsColor(false);
                    setOpenAutoModel(false);
                    setOpenAutoBrendsColor(false);
                    setOpenSeatsCount(false);
                    setOpenAutoYear(false)
                  }}
                >
                  {avtoBrend}<FaSortDown />
                </button>
              }
              menu={
                cars.map((carMaker) =>
                  <button onClick={() => { setOpenAutoBrends(!openAutoBrends); setAutoBrend(carMaker.Make) }}>{carMaker.Make}</button>,
                )
              }
            />
            <Dropdown
              open={openAutoModel}
              trigger={
                <button
                  onClick={() => {
                    setOpen(false);
                    setOpenAutoBrends(false);
                    setOpenAutoModel(false);
                    setOpenAutoBrendsColor(false);
                    setOpenAutoModel(!openAutoModel);
                    setOpenAutoBrendsColor(false);
                    setOpenSeatsCount(false);
                    setOpenAutoYear(false)
                  }}
                >{autoModel}<FaSortDown />
                </button>
              }
              menu={
                cars.find((car) => car.Make === avtoBrend)?.Details?.map((details) => (
                  <button onClick={() => { setOpenAutoModel(!openAutoModel); setAutoModel(details.ModelName); }}>{details.ModelName}</button>
                ))
              }
            />
            <Dropdown
              open={openAutoBrendsColor}
              trigger={
                <button
                  onClick={() => {
                    setOpen(false);
                    setOpenAutoBrends(false);
                    setOpenAutoModel(false);
                    setOpenAutoBrendsColor(!openAutoBrendsColor);
                    setOpenAutoModel(false);
                    setOpenSeatsCount(false);
                    setOpenAutoYear(false)
                  }}
                >{avtoBrendColor}<FaSortDown />
                </button>
              }
              menu={
                carColors.map((color) =>
                  <button onClick={() => { setOpenAutoBrendsColor(!openAutoBrendsColor); setAutoBrendColor(color.color) }}>{color.color}</button>,
                )
              }
            /> */}

            {/* <Dropdown
              open={openAutoYear}
              trigger={
                <button
                  onClick={() => {
                    setOpen(false);
                    setOpenAutoBrends(false);
                    setOpenAutoModel(false);
                    setOpenAutoBrendsColor(false);
                    setOpenAutoModel(false);
                    setOpenAutoBrendsColor(false);
                    setOpenSeatsCount(false);
                    setOpenAutoYear(!openAutoYear)
                  }}
                >
                  {autoYear}<FaSortDown />
                </button>
              }
              menu={
                cars.find((car) => car.Make === avtoBrend)?.Details?.
                  find((details) => details.ModelName === autoModel)?.Year?.
                  map((year) => (
                    <button onClick={() => { setOpenAutoYear(!openAutoYear); setAutoYear(year); }}>{year}</button>
                  ))
              }
            /> */}
            <Input placeholder='Şəxsiyyə V.' value={id} onchange={e => setId(e.target.value)} />
            <Input placeholder='Dövlət qeydiyyat nömrəsi (XX-HH-XXX)' value={carNumber} onchange={e => handleCarNumberChange(e)} />
            <Input placeholder='Brend' value={avtoBrend} onchange={e => setAutoBrend(e.target.value)} />
            <Input placeholder='Model' value={autoModel} onchange={e => setAutoModel(e.target.value)} />
            <Input placeholder='İl' value={autoYear} onchange={e => setAutoYear(e.target.value)} />
            <Input placeholder='Rəng' value={avtoBrendColor} onchange={e => setAutoBrendColor(e.target.value)} />

            <Button text='Göndər' click={() => showAllParameters()}></Button>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default Drivers;