
import React from 'react';
import './Input.css'

const Input = ({ placeholder, small, read, type, onchange, value }) => {
    return (
        <div className='input'>
            {read ?
                <input value={value} type={type ? `${type}` : 'text'} className={small && 'small'} placeholder={placeholder} readOnly onChange={onchange}></input>
                :
                <input value={value} type={type ? `${type}` : 'text'} className={small && 'small'} placeholder={placeholder} onChange={onchange}></input>
            }

        </div>
    );
};

export default Input;
