import React, { useEffect, useState } from 'react';
import './Login.css';
import Input from '../../components/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileNumberUsed, loginAction, registrationAction, isPasswordCorrect, isUserExist } from '../../redux/action';
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const [header, setHeader] = useState("Daxil ol")
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("+994(")
    const [password, setPassword] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userInfo = useSelector(state => state.userInfo)
    const isMobilenumUsed = useSelector(state => state.isMobilenumUsed)
    const isUsrExist = useSelector(state => state.isUserExist)
    const isPwdCorrect = useSelector(state => state.isPasswordCorrect)
    const [isNameCorrectWrote, setIsNameCorrectWrote] = useState(true)
    const [isLastnameCorrectWrote, setIsLastnameCorrectWrote] = useState(true)
    const [isPhonenumCorrectWrote, setIsPhonenumCorrectWrote] = useState(true)
    const [isPasswordCorrectWrote, setIsPasswordCorrectWrote] = useState(true)
    useEffect(() => {
        userInfo?.accessToken !== undefined && navigate("/")
        userInfo?.accessToken !== undefined && localStorage.setItem("token", userInfo?.accessToken)
        userInfo?.mobileNum !== undefined && localStorage.setItem("fleetId", userInfo?.mobileNum)
    }, [userInfo])
    const LoginBID = (e) => {
        dispatch(isMobileNumberUsed(false))
        dispatch(isPasswordCorrect(true))
        dispatch(isUserExist(true))
        if (header === "Qeydiyyat") {
            return setHeader("Daxil ol")
        }
        else {
            phoneNumber.replaceAll(" ", "").length < 17 ? setIsPhonenumCorrectWrote(false) : setIsPhonenumCorrectWrote(true)
            password.replaceAll(" ", "") === "" ? setIsPasswordCorrectWrote(false) : setIsPasswordCorrectWrote(true)
        }
        if (header === "Daxil ol" && phoneNumber.replaceAll(" ", "").length === 17 && password.replaceAll(" ", "") !== "") {
            dispatch(loginAction(phoneNumber, password))
        }
    }
    const RegistrationBID = (e) => {
        dispatch(isMobileNumberUsed(false))
        dispatch(isPasswordCorrect(true))
        dispatch(isUserExist(true))
        if (header === "Daxil ol") {
            return setHeader("Qeydiyyat")
        }
        else {
            name.replaceAll(" ", "") === "" ? setIsNameCorrectWrote(false) : setIsNameCorrectWrote(true)
            surname.replaceAll(" ", "") === "" ? setIsLastnameCorrectWrote(false) : setIsLastnameCorrectWrote(true)
            phoneNumber.replaceAll(" ", "").length < 17 ? setIsPhonenumCorrectWrote(false) : setIsPhonenumCorrectWrote(true)
            password.replaceAll(" ", "") === "" ? setIsPasswordCorrectWrote(false) : setIsPasswordCorrectWrote(true)
        }
        if (header === "Qeydiyyat" && name.replaceAll(" ", "") !== "" && surname.replaceAll(" ", "") !== "" && phoneNumber.replaceAll(" ", "").length === 17 && password.replaceAll(" ", "") !== "") {
            dispatch(registrationAction(name, surname, phoneNumber, password))
        }
    }
    const handlePhoneChange = (e) => {
      var len = e.target.value?.length;
      if (e.nativeEvent.inputType === 'deleteContentBackward') {
        if (len === 14) {
          return setPhoneNumber(e.target.value.slice(0, 13));
        }
        else if (len === 11) {
          return setPhoneNumber(e.target.value.slice(0, 10));
        }
        else if (len === 7) {
          return setPhoneNumber(e.target.value.slice(0, 6));
        }
      }
      if (e.target.value?.length < 18 && !isNaN(e.target.value.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', ''))) {
        if (e.target.value?.slice(0, 5) != "+994(" && e.target.value?.slice(0, 4) != "+994" && e.target.value?.slice(0, 3) != "+99" && e.target.value?.slice(0, 2) != "+9" && e.target.value?.slice(0, 1) != "+") {
          setPhoneNumber("+994(" + e.target.value);
        }
        else if (len === 4 && (!phoneNumber.endsWith('-') && !phoneNumber.endsWith('(') && !phoneNumber.endsWith(')'))) {
          setPhoneNumber(e.target.value + "(");
        }
        else if (len === 7 && (!phoneNumber.endsWith('-') && !phoneNumber.endsWith('(') && !phoneNumber.endsWith(')'))) {
          setPhoneNumber(e.target.value + ")");
        }
        else if ((len === 11 || len === 14) && (!phoneNumber.endsWith('-') && !phoneNumber.endsWith('(') && !phoneNumber.endsWith(')'))) {
          setPhoneNumber(e.target.value + "-");
        }
        else if (len < 18 && len > 4) {
          setPhoneNumber(e.target.value);
        }
      }
    };
    return (
        <div className='full-container'>
            <div className='login-container'>
                <div className='header'>
                    <div className='text'>{header}</div>
                    <div className='underline'></div>
                </div>
                <div className='inputs'>
                    {header === "Qeydiyyat" &&
                        <>
                            <Input placeholder='Ad' onchange={(e) => setName(e.target.value)}></Input>
                            {!isNameCorrectWrote && <div className='forgot-password error_css'>*Zəhmət olmasa adınızı qeyd edin</div>}
                            <Input placeholder='Soyad' onchange={(e) => setSurname(e.target.value)}></Input>
                            {!isLastnameCorrectWrote && <div className='forgot-password error_css'>*Zəhmət olmasa soyadınızı qeyd edin</div>}
                        </>
                    }
                    <Input placeholder='Telefon nömrəsi (+994XX-XXX-XX-XX)' onchange={e => handlePhoneChange(e)} value={phoneNumber}></Input>
                    {!isPhonenumCorrectWrote && <div className='forgot-password error_css'>*Zəhmət olmasa nömrənizi qeyd edin</div>}
                    <Input placeholder='Parol' type={"password"} onchange={(e) => setPassword(e.target.value)}></Input>
                    {!isPasswordCorrectWrote && <div className='forgot-password error_css'>*Zəhmət olmasa parolu qeyd edin</div>}
                </div>
                {!isPwdCorrect && <div className='forgot-password error_css'>*Parol yanlisdir</div>}
                {!isUsrExist && <div className='forgot-password error_css'>*User tapilmadi</div>}
                {isMobilenumUsed && <div className='forgot-password error_css'>*Bu user artıq qeydiyyatdan kecib</div>}
                <div className='forgot-password'>Parolunuzu unutmusunuz? <span> kömək edək </span></div>
                <div className='submit-container'>
                    {
                        header === "Qeydiyyat" ?
                            <>
                                <div className={header === 'Daxil ol' ? "submit gray" : "submit"} onClick={(e) => RegistrationBID(e)}>Qeydiyyat</div>
                                <div className={header === 'Qeydiyyat' ? "submit gray" : "submit"} onClick={(e) => LoginBID(e)}>Daxil ol</div>
                            </>
                            :
                            <>
                                <div className={header === 'Qeydiyyat' ? "submit gray" : "submit"} onClick={(e) => LoginBID(e)}>Daxil ol</div>
                                <div className={header === 'Daxil ol' ? "submit gray" : "submit"} onClick={(e) => RegistrationBID(e)}>Qeydiyyat</div>
                            </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Login;
