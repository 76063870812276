import './Support.css';
import React, { useEffect, useState } from 'react';
import Dropdown from '../../components/Dropdown/Dropdown';
import { FaSortDown, FaTimes, FaDotCircle, FaMapMarkerAlt } from "react-icons/fa";
import DataTable from 'react-data-table-component';
import Button from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getDriversAction, getSupportAction } from '../../redux/action';
const Support = () => {
  const [driverOpen, setDriverOpen] = useState(false);
  const [driver, setDriver] = useState("Hamısı");
  const [driverId, setDriverId] = useState(null);
  const [reasonOpen, setReasonOpen] = useState(false);
  const [reason, setReason] = useState("Hamısı");
  const [reasonId, setReasonId] = useState(null)
  const [detailVisible, setDetailVisible] = useState(false)
  const [detailProfilId, setDetailProfilId] = useState(0)
  const reasons = ["Maşında unudulan əşyalar", "Şikayət", "Digər"]
  const columns = [
      {
          name: 'Sürücü',
          selector: row => row.driverId,
          sortable: true,
          compact: true,
          center: true,
          cell: (row) => (
              <div className='cell-item'><img className='image' height="50px" width="50px" alt={row.driver.name + " " + row.driver.lastname} src={row.driver.image ? row.driver.image : "assets/img/default_image.png"} /><span>{row.driver.name + " " + row.driver.lastname}</span></div>
          )
      },
      {
          name: 'Mövzu',
          selector: row => reasons[row.supportType],
          sortable: true,
          center: true,
          compact: true
      },
      {
          name: 'Detal',
          selector: row => row.detail,
          sortable: true,
          center: true,
          compact: true
      },
      {
          name: 'Tarix',
          selector: row => row?.createdAt?.replace("Z","").split("T")[1].slice(0, 5) + " " + row?.createdAt?.replace("Z","").split("T")[0],
          sortable: true,
          center: true,
          compact: true
      },
  ]
  const customStyles = useSelector(state => state.supportCustomstyle)
  const data = useSelector(state => state.supportData)
  const dispatch = useDispatch()
  const [supportType, setSupportType] = useState('0') 
  const drivers = useSelector(state => state.data)
  useEffect(() => {
    dispatch(getDriversAction(localStorage.getItem("fleetId")))
  }, [])
  useEffect(() => {
    dispatch(getSupportAction())
  }, [])
  useEffect(() => {
    dispatch(getSupportAction(driverId != null ? driverId : undefined, reasonId != null ? reasonId : undefined))
  }, [driverId, reasonId])
  return (
    <div className='container'>
      <div className='flex'>
        <div className={detailVisible ? 'modal-container' : 'hidden'}>
          <div className='modal'>
            <div className='modal-header'>
              <h3>{reasons[supportType]}</h3>
              <FaTimes onClick={() => setDetailVisible(false)} />
            </div>
            <div className='head'>
              <img className='image' height="40px" width="40px" src={data?.find((taxi) => taxi?.driverId == detailProfilId)?.driver.image !== null ? data?.find((taxi) => taxi?.driverId == detailProfilId)?.driver.image : "assets/img/default_image.png" } alt={data?.find((taxi) => taxi?.driverId == detailProfilId)?.driver.name + " " + data?.find((taxi) => taxi?.driverId == detailProfilId)?.driver.lastname} />
              <label>{data?.find((taxi) => taxi?.driverId == detailProfilId)?.driver.name + " " + data?.find((taxi) => taxi?.driverId == detailProfilId)?.driver.lastname}</label>
            </div>
            <hr />
            <div className='modal-content'>
              {/* <div className='bold'>
                {data?.find((taxi) => taxi?.driverId == detailProfilId)?.driver.name + " " + data?.find((taxi) => taxi?.driverId == detailProfilId)?.driver.lastname}
              </div> */}
                {/* <div className='date'> */}
                  {data?.find((taxi) => taxi?.driverId == detailProfilId)?.createdAt?.replace("Z","").split("T")[1].slice(0, 5) + " " + data?.find((taxi) => taxi?.driverId == detailProfilId)?.createdAt?.replace("Z","").split("T")[0]}
                {/* </div> */}
              <div>
                {data?.find((taxi) => taxi?.driverId == detailProfilId)?.detail}
              </div>
              {/* <div className='column border'>
                <div className='date'>
                  {data?.find((taxi) => taxi?.driverId == detailProfilId)?.createdAt?.replace("Z","").split("T")[1].slice(0, 5) + " " + data?.find((taxi) => taxi?.driverId == detailProfilId)?.createdAt?.replace("Z","").split("T")[0]}
                </div>
                <div className='flex'>
                  <FaDotCircle color='#6F52EA' />{data?.find((taxi) => taxi?.id == detailProfilId)?.locationFrom}
                </div>
                <div className='flex'>
                  <FaMapMarkerAlt color='#6F52EA' />{data?.find((taxi) => taxi?.id == detailProfilId)?.locationTo}
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div>
          <label>Sürücü</label>
          <Dropdown
            open={driverOpen}
            trigger={<button onClick={() => setDriverOpen(!driverOpen)}>{driver}<FaSortDown /></button>}
            menu={
              drivers ? (
                [
                  <button key="all" onClick={() => { setDriverOpen(!driverOpen); setDriverId(null); setDriver('Hamısı') }}>Hamısı</button>,
                  ...drivers.map((driver) => (
                    <button key={driver.driverId} onClick={() => {
                      setDriverOpen(!driverOpen);
                      setDriverId(driver.driverId);
                      setDriver(driver.driver?.name + " " + driver.driver?.lastname)
                    }}>
                      {driver.driver?.name + " " + driver.driver?.lastname}
                    </button>
                  ))
                ]
              ) : (
                <button onClick={() => {
                  setDriverOpen(!driverOpen);
                  setDriverId(null);
                  setDriver("Hamısı")
                }}>Hamısı</button>
              )
            }
          />
        </div>
        <div>
          <label>Mövzu</label>
          <Dropdown
            open={reasonOpen}
            trigger={<button onClick={() => setReasonOpen(!reasonOpen)}>{reason}<FaSortDown /></button>}
            menu={[
              <button onClick={() => { setReasonOpen(!reasonOpen); setReasonId(null); setReason('Hamısı') }}>Hamısı</button>,
              <button onClick={() => { setReasonOpen(!reasonOpen); setReasonId(0); setReason("Maşında unudulan əşyalar") }}>Maşında unudulan əşyalar</button>,
              <button onClick={() => { setReasonOpen(!reasonOpen); setReasonId(1); setReason("Şikayət") }}>Şikayət</button>,
              <button onClick={() => { setReasonOpen(!reasonOpen); setReasonId(2); setReason("Digər") }}>Digər</button>,
            ]}
          />
        </div>
      </div>
      <div className='table-container'>
        <DataTable
          columns={columns}
          data={data}
          pagination
          highlightOnHover={true}
          customStyles={customStyles}
          onRowClicked={(e) => { setDetailProfilId(e?.driverId); setDetailVisible(true);setSupportType(e?.supportType) }}
        />
      </div>
    </div>
  );
};

export default Support;