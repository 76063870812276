import './Drivers.css';
import { FaChevronLeft, FaStar, FaDotCircle, FaCheck } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { FaTimes } from "react-icons/fa";
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { saveDriverIdAction, saveImageAction, saveNationalIdAction, saveTaxiIdAction, getDriverByIdAction } from '../../redux/action';

const DriverDetails = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [driverState, setDriverState] = useState([])
    const [peviewDocument, setPreviewDocument] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [modalTitle, setModalTitle] = useState("Sənəd");
    const [modalImg, setModalImg] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null);
    const [peviewDownloadDocument, setPeviewDownloadDocument] = useState(false)
    const driverById = useSelector(state => state.driverById)
    const showDocument = (e) => {
        if (e.target.className != "button gray") {
            setPreviewDocument(true)
        }
    }
    useEffect(() => {
        try {
            setDriverState(state?.row)
        }
        catch {
            console.log("")
        }
    }, [])
    useEffect(() => {
        dispatch(getDriverByIdAction(window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]))
        setDriverState(driverById)
    }, [modalImg])
    return (
        <div className='container'>
            <div className='detail-header' onClick={() => navigate("/")} >
                <FaChevronLeft /> Sürücülərə gedin
            </div>
            <div className='detail-container'>
                <img className='image' height="100px" width="100px" alt={driverById?.driver?.name + " " + driverById?.driver?.lastname} src={driverById?.driver?.image ? driverById?.driver.image : "http://80.241.208.93:3333/assets/img/default_image.png"} />
                <div className='bold'>
                    {driverById?.driver?.name + " " + driverById?.driver?.lastname} 5 <FaStar size={12} />
                </div>
                <div>
                    {driverById?.driver?.mobileNum}
                </div>
                {/* <div className={driverById?.status === 1 ? 'flex green' : 'flex red'}>
                    <FaDotCircle /> {driverById?.status === 1 ? "Online" : "Offline"}
                </div> */}
            </div>
            <hr></hr>
            <div className='detail-documents'>
                <div className='bold'>Sənədlər</div>
                <div className='documents-container'>
                    <div className='document'>
                        <div className='documentl' onClick={e => { setModalImg(driverById?.driver?.nationalId); setModalTitle("Şəxsiyyət vəsiqəsi"); showDocument(e) }}>
                            Şəxsiyyət vəsiqəsi
                            {
                                driverById?.driver?.nationalId ?
                                    <div className='check green'>
                                        <FaCheck /> təsdiq edilib
                                    </div> :
                                    <div className='check red'>
                                        təsdiq edilməyib
                                    </div>
                            }
                        </div>
                        <div className='input customerFileBtn downloadInput'>
                            <Button text="yüklə" upload gray download></Button>
                            <input
                                className='inputGroupFile'
                                type="file"
                                name="myImage"
                                accept="image/*"
                                onChange={(event) => {
                                    setSelectedImage(event.target.files[0]);
                                    setModalImg(URL.createObjectURL(event.target.files[0]));
                                    setModalTitle("Şəxsiyyət vəsiqəsi")
                                    setPeviewDownloadDocument(true)
                                    // dispatch(saveNationalIdAction(event.target.files[0], driverById?.driverId, driverById?.driver?.nationalId))
                                }}
                            />
                        </div>
                    </div>
                    <div className='document'>
                        <div className='documentl' onClick={e => { setModalImg(driverById?.driver?.driverId); setModalTitle("Sürücülük vəsiqəsi"); showDocument(e) }}>
                            Sürücülük vəsiqəsi
                            {
                                driverById?.driver?.driverId ?
                                    <div className='check green'>
                                        <FaCheck /> təsdiq edilib
                                    </div> :
                                    <div className='check red'>
                                        təsdiq edilməyib
                                    </div>
                            }
                        </div>
                        <div className='input customerFileBtn downloadInput'>
                            <Button text="yüklə" upload gray download></Button>
                            <input
                                className='inputGroupFile'
                                type="file"
                                name="myImage"
                                accept="image/*"
                                onChange={(event) => {
                                    setSelectedImage(event.target.files[0]);
                                    setModalImg(URL.createObjectURL(event.target.files[0]));
                                    setModalTitle("Sürücülük vəsiqəsi")
                                    setPeviewDownloadDocument(true)
                                }}
                            />
                        </div>
                    </div>
                    <div className='document'>
                        <div className='documentl' onClick={e => { setModalImg(driverById?.driver?.taxiId); setModalTitle("Taksi lisenziyası"); showDocument(e) }}>
                            Taksi lisenziyası
                            {
                                driverById?.driver?.taxiId ?
                                    <div className='check green'>
                                        <FaCheck /> təsdiq edilib bitmə vaxtı: 01-01-2024
                                    </div> :
                                    <div className='check red'>
                                        təsdiq edilməyib
                                    </div>
                            }
                        </div>
                        <div className='input customerFileBtn downloadInput'>
                            <Button text="yüklə" upload gray download></Button>
                            <input
                                className='inputGroupFile'
                                type="file"
                                name="myImage"
                                accept="image/*"
                                onChange={(event) => {
                                    setSelectedImage(event.target.files[0]);
                                    setModalImg(URL.createObjectURL(event.target.files[0]));
                                    setModalTitle("Taksi lisenziyası")
                                    setPeviewDownloadDocument(true)
                                }}
                            />
                        </div>
                    </div>
                    <div className='document'>
                        <div className='documentl' onClick={e => { setModalImg(driverById?.profileImg); setModalTitle("Profil şəkli"); showDocument(e) }}>
                            Profil şəkli
                            {
                                driverById?.driver?.profileImg ?
                                    <div className='check green'>
                                        <FaCheck /> təsdiq edilib
                                    </div> :
                                    <div className='check red'>
                                        təsdiq edilməyib
                                    </div>
                            }
                        </div>
                        <div className='input customerFileBtn downloadInput'>
                            <Button text="yüklə" upload gray download></Button>
                            <input
                                className='inputGroupFile'
                                type="file"
                                name="myImage"
                                accept="image/*"
                                onChange={(event) => {
                                    setSelectedImage(event.target.files[0]);
                                    setModalImg(URL.createObjectURL(event.target.files[0]));
                                    setModalTitle("Profil şəkli")
                                    setPeviewDownloadDocument(true)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={peviewDocument ? 'modal-container' : 'hidden'}>
                <div className='modal'>
                    <div className='modal-header'>
                        <h3>{modalTitle}</h3>
                        <FaTimes onClick={e => setPreviewDocument(false)} />
                    </div>
                    <div className='head'>
                        <img className='image' height="40px" width="40px" alt={driverById?.driver?.name + " " + driverById?.driver?.lastname} src={driverById?.driver?.image !== null ? driverById?.driver?.image : "http://80.241.208.93:3333/assets/img/default_image.png"} />
                        <label>{driverById?.driver?.name + " " + driverById?.driver?.lastname}</label>
                    </div>
                    {modalTitle == "Taksi lisenziyası" ? <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> : <></>}
                    <hr />
                    <div className='modal-content'>
                        <img className='image' height="100%" width="100%" alt={driverById?.image} src={modalImg} />
                    </div>
                </div>
            </div>
            <div className={peviewDownloadDocument ? 'modal-container' : 'hidden'}>
                <div className='modal'>
                    <div className='modal-header'>
                        <h3>{modalTitle}</h3>
                        <FaTimes onClick={e => setPeviewDownloadDocument(false)} />
                    </div>
                    <div className='head'>
                        <img className='image' height="40px" width="40px" alt={driverById?.driver?.name + " " + driverById?.driver?.lastname} src={driverById?.driver?.image ? driverById?.driver?.image : "http://80.241.208.93:3333/assets/img/default_image.png"} />
                        <label>{driverById?.driver?.name + " " + driverById?.driver?.lastname}</label>
                    </div>
                    {modalTitle == "Taksi lisenziyası" ? <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> : <></>}
                    <hr />
                    <div className='modal-content'>
                        {modalImg ? <img className='image' height="100%" width="100%" alt={driverById?.name} src={modalImg ? modalImg : ""} /> : <></>}
                    </div>
                    <div className='downloadModalBtn'>
                        {modalTitle == "Şəxsiyyət vəsiqəsi" && <Button text="yüklə" upload gray click={e => { dispatch(saveNationalIdAction(modalImg, driverById?.driverId, driverById?.driver?.nationalId)); setModalImg(null); setPeviewDownloadDocument(false) }}></Button>}
                        {modalTitle == "Sürücülük vəsiqəsi" && <Button text="yüklə" upload gray click={e => { dispatch(saveDriverIdAction(modalImg, driverById?.driverId, driverById?.driver?.nationalId)); setModalImg(null); setPeviewDownloadDocument(false) }}></Button>}
                        {modalTitle == "Taksi lisenziyası" && <Button text="yüklə" upload gray click={e => { dispatch(saveTaxiIdAction(modalImg, driverById?.driverId, driverById?.driver?.nationalId)); setModalImg(null); setPeviewDownloadDocument(false) }}></Button>}
                        {modalTitle == "Profil şəkli" && <Button text="yüklə" upload gray click={e => { dispatch(saveImageAction(modalImg, driverById?.driverId, driverById?.driver?.nationalId)); setModalImg(null); setPeviewDownloadDocument(false) }}></Button>}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DriverDetails;