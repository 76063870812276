import React, {useState} from 'react';
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavMenu2,
} from './NavbarElements';
import { useDispatch } from 'react-redux';
import { loginBid } from '../../redux/action';

const Navbar = () => {
    const [showMobile, setShowMobile] = useState(false)
    const dispatch = useDispatch()
    const LogOut = () => {
        dispatch(loginBid([]))
        localStorage.clear()
        localStorage.removeItem("fleetId");
        localStorage.removeItem("token");
        window.location.reload();
    }
    return (
        <>
            <Nav>
                <Bars className='bar' onClick={(e) => setShowMobile(!showMobile)} />
                <NavMenu>
                    <NavLink to='/drivers' activeStyle>
                        Sürücülər
                    </NavLink>
                    {/* <NavLink to='/map' activeStyle>
                        Xəritə
                    </NavLink>
                    <NavLink to='/payment' activeStyle>
                        Ödənişlər
                    </NavLink>
                    <NavLink to='/support' activeStyle>
                        Dəstək
                    </NavLink>
                    <NavLink to='/documents' activeStyle>
                        Sənədlər
                    </NavLink> */}
                      <NavLink to='/report' activeStyle>
                        User Report
                    </NavLink>
                      <NavLink to='/driverReport' activeStyle>
                        Driver Report
                    </NavLink>
                    <NavLink to='/login' onClick={e => LogOut()} activeStyle className="logoutCl">
                        Cıxmaq
                    </NavLink>
                </NavMenu>
                {showMobile && <NavMenu2>
                    <NavLink to='/drivers' activeStyle onClick={(e) => setShowMobile(false)} >
                        Sürücülər
                    </NavLink>
                    {/* <NavLink to='/map' activeStyle onClick={(e) => setShowMobile(false)} >
                        Xəritə
                    </NavLink>
                    <NavLink to='/payment' activeStyle onClick={(e) => setShowMobile(false)} >
                        Ödənişlər
                    </NavLink>
                    <NavLink to='/support' activeStyle onClick={(e) => setShowMobile(false)} >
                        Dəstək
                    </NavLink>
                    <NavLink to='/documents' activeStyle onClick={(e) => setShowMobile(false)} >
                        Sənədlər
                    </NavLink> */}
                    <NavLink to='/report' onClick={e => setShowMobile(false)} activeStyle>
                        User Report
                    </NavLink>
                    <NavLink to='/driverreport' onClick={e => setShowMobile(false)} activeStyle>
                        Driver Report
                    </NavLink>
                    <NavLink to='/login' onClick={e => LogOut()} activeStyle className="logoutCl">
                        Cıxmaq
                    </NavLink>
                </NavMenu2>}
            </Nav>
        </>
    );
};

export default Navbar;
