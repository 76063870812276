// components/LineChart.js
import { Line } from "react-chartjs-2";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOffersAction } from '../../redux/action';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

const AllOffersChart = () => {
    const data = useSelector(state => state.allOffers)

    Chart.register(CategoryScale);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllOffersAction())
    }, [])


    const [chartData, setChartData] = useState({labels: [], datasets: []})
    useEffect(() => {
        if (data) {
            setChartData({
                labels: data?.offers?.map((d) => d.date),
                datasets: [
                    {
                        label: "Offer",
                        data: data?.offers?.map((d) => d.count),
                        backgroundColor: '#6F52EA',
                        borderColor: "#6F52EA",
                        borderWidth: 1
                    }
                ]
            })
        }
    }, [data]);
    return (

        <div style={{ width: '70%' }}>
            <Line
                data={chartData}
                options={{
                    plugins: {
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        }
                    }
                }}
            />
        </div>

    );

};
export default AllOffersChart