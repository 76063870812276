// components/BarChart.js
import { Bar } from "react-chartjs-2";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDriverRejectedOffersAction } from '../../redux/action';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

const RejectedDriverOffersChart = () => {
    const data = useSelector(state => state.rejectedOffersD)

    Chart.register(CategoryScale);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getDriverRejectedOffersAction())
    }, [])

    const [chartData, setChartData] = useState({labels: [], datasets: []})
    useEffect(() => {
        if (data) {
            setChartData({
                labels: data?.offers?.map((d) => d.date),
                datasets: [
                    {
                        label: "Trip",
                        data: data?.offers?.map((d) => d.count),
                       // backgroundColor: [],
                        borderColor: "#6F52EA",
                        backgroundColor: '#6F52EA',
                        borderWidth: 1
                    }
                ]
            })
        }
    }, [data]);
    return (
        <div style={{ width: '90%'}}>

        <Bar
            data={chartData}
            options={{
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false
                    }
                }
            }}
        />
        </div>
    );
};
export default RejectedDriverOffersChart