import './App.css';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Login from './pages/Login/Login'
import Drivers from './pages/Drivers/Drivers'
import DriverDetails from './pages/Drivers/DriverDetails'
import Map from './pages/Map/Map'
import Documents from './pages/Documents/Documents'
import Support from './pages/Support/Support'
import Payment from './pages/Payment/Payment'
import PassengerChart from './pages/PassengerChart/PassengerChart'
import DriverReport from './pages/DriverReport/DriverReport'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { verticalOrHorizontal } from './redux/action';
function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    if (localStorage.getItem("token") === undefined || !localStorage.getItem("token")) {
      return navigate("/login")
    }
    navigate("/")
  }, [localStorage.getItem("token")])
  useEffect(() => {
    if (window.innerWidth < 1190) {
      dispatch(verticalOrHorizontal("vertical"))
    }
    else {
      dispatch(verticalOrHorizontal("horizontal"))
    }
  }, [window.innerWidth])
  return (
    <>
      {window.location.pathname !== "/login" && <Navbar />}
      <Routes>
        {localStorage.getItem("token") !== undefined &&
          <>
            <Route path="/" element={<Drivers />} />
            <Route path="/drivers" element={<Drivers />} />
            <Route path="/driver_detail/*" element={<DriverDetails />} />
            <Route path="/map" element={<Map />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/support" element={<Support />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/report" element={<PassengerChart />} />
            <Route path="/driverReport" element={<DriverReport />} />
          </>
        }
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
