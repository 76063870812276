import React from 'react';
import './Button.css'
import { FaUserPlus } from "react-icons/fa";

const Button = ({ gray, text, full, red, small, rounded, deleted, black, isActive, add, click }) => {
    const classNames = [
        "button",
        gray && "gray",
        full && "full",
        red && "red",
        small && "small",
        rounded && "rounded",
        deleted && "deleted",
        black && "black",
        isActive && "active",
    ].filter(e => e).join(" ");

    return (
        <div className={classNames} onClick={click}>
            {add &&
                <FaUserPlus />
            }
            {text}
        </div>
    );
};

export default Button;
