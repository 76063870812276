import React, { useState } from 'react';
import './DriverBox.css'
import { FaChevronRight } from "react-icons/fa";
import Button from '../Button/Button'
import { exportPdfAction } from '../../redux/action';
import { useDispatch } from 'react-redux';
const DriverBox = ({ box }) => {
    const [toggle, setToggle] = useState(false)
    const dispatch = useDispatch()
    return (    
        <div className='box'>
            <div className='head'>
                <img className='image' height="40px" width="40px" src={box.image ? box.image : "assets/img/default_image.png"} alt={box.name + ' ' + box.lastname} />
                <label>{box.name + ' ' + box.lastname}</label>
            </div>
            {
                box.isPayed ?
                    <div className='row bold green' onClick={() => setToggle(!toggle)} style={{ cursor: 'pointer' }}>
                        <label>Qazanc</label>
                        <div className='row'>{Array.isArray(box.sum) ? <>{(box.sum[0]?.sum + box.sum[1]?.sum).toFixed(2) !== "NaN" ? (box.sum[0]?.sum + box.sum[1]?.sum).toFixed(2) : "0.00"}</> : "0.00"} ₼<FaChevronRight /></div>
                    </div>
                    :
                    <div className='row bold red' onClick={() => setToggle(!toggle)} style={{ cursor: 'pointer' }}>
                        <label>Qazanc</label>
                        <div className='row'>{Array.isArray(box.sum) ? <>{(box.sum[0]?.sum + box.sum[1]?.sum).toFixed(2) !== "NaN" ? (box.sum[0]?.sum + box.sum[1]?.sum).toFixed(2) : "0.00"}</> : "0.00"} ₼<FaChevronRight /></div>
                    </div>
            }
            <div className={toggle ? 'column light' : 'hidden'}>
                <div className='flex spacebetween'>
                    <div>
                        Nağd
                    </div>
                    <div>
                        {Array.isArray(box.sum) ? box.sum[0]?.sum.toFixed(2) : "0.00"} ₼
                    </div>
                </div>
                <div className='flex spacebetween'>
                    <div>
                        Kredit kartı
                    </div>
                    <div>
                        {Array.isArray(box.sum) ? box.sum[1]?.sum.toFixed(2) : "0.00"} ₼
                    </div>
                </div>
                <div className='flex spacebetween'>
                    <div>
                        Bonus
                    </div>
                    <div>
                        {box.cash} ₼
                    </div>
                </div>
                <div className='flex spacebetween'>
                    <div>
                        Tips
                    </div>
                    <div>
                        {box.creditCard} ₼
                    </div>
                </div>
                <div className='flex spacebetween'>
                    <div>
                        Xidmət haqqı
                    </div>
                    <div>
                        {box.servicePayment}  ₼
                    </div>
                </div>
                <div className='flex spacebetween'>
                    <div>
                        Vergi
                    </div>
                    <div>
                        {box.taxes} ₼
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className='row'>
                <label>Səfər sayı</label>
                <div>{box.trips}</div>
            </div>
            <div className='row'>
                <label>Balans</label>
                <div>{box.balance.toFixed(2)} ₼</div>
            </div>
            <div className='row'>
                <Button text="qəbzi yükləyin" gray full click={e => dispatch(exportPdfAction(box))}></Button>
            </div>
        </div>
    );
};

export default DriverBox;
