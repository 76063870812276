import axios from 'axios';
import Swal from 'sweetalert2'
import downloadjs from "downloadjs";
export function verticalOrHorizontal(value) {
    return {
        type: "VERTICAL_OR_HORIZONTAL",
        payload: {
            value: value
        }
    }
}
export function whichWeek(week) {
    return {
        type: "WHICH_WEEK",
        payload: {
            value: week
        }
    }
}
export function weekString(week) {
    return {
        type: "WEEK_STRING",
        payload: {
            value: week
        }
    }
}
export function isPasswordCorrect(value) {
    return {
        type: "IS_PASSWORD_CORRECT",
        payload: {
            value: value
        }

    }
}
export function isUserExist(value) {
    return {
        type: "IS_USER_EXIST",
        payload: {
            value: value
        }

    }
}
export function isMobileNumberUsed(value) {
    return {
        type: "IS_MOBILENUMBER_USED",
        payload: {
            value: value
        }

    }
}
export function loginBid(loginData) {
    return {
        type: "LOGIN",
        payload: {
            value: loginData
        }
    }
}
export function bidToFleetWeekValuesBid(value) {
    return {
        type: "BID_TO_FLEET_WEEK_VALUE",
        payload: {
            value: value
        }
    }
}
export function changeMonthBid(value) {
    return {
        type: "CHANGED_MONTH",
        payload: {
            value: value
        }
    }

}
export function loginAction(phoneNumber, password) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/login', {
            mobileNum: phoneNumber.replaceAll("-", "").replaceAll("(", "").replaceAll(")", ""),
            password: password
        }).
            then((response) => {
                dispatch(loginBid(response.data.data));
            }).
            catch(error => {
                if (error.response.data.message === "Invalid Password!") {
                    dispatch(isPasswordCorrect(false))
                }
                else if (error.response.data.message === "User Not found.") {
                    dispatch(isUserExist(false))
                }
            })
    }
}
export function registrationAction(name, surname, phoneNumber, password) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/register', {
            name: name,
            surname: surname,
            mobileNum: phoneNumber.replaceAll("-", "").replaceAll("(", "").replaceAll(")", ""),
            password: password
        }).
            then((response) => {
                dispatch(loginBid(response.data.data));
                Swal.fire({
                    title: 'Uğurlu',
                    text: 'Siz uğurla qeydiyyatdan keçdiniz.',
                    icon: 'success',
                });
            }).
            catch(error => {
                if (error.response.data.message === "Mobile number is already in use!") {
                    dispatch(isMobileNumberUsed(true))
                }
                else {
                    Swal.fire({
                        title: 'Uğursuz',
                        text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                        icon: 'error',
                    });
                }
            })
    }
}
export function getDriversBid(data) {
    return {
        type: "GET_DRIVERS",
        payload: {
            value: data
        }
    }
}
export function getInvitesBid(data) {
    return {
        type: "GET_INVITES",
        payload: {
            value: data
        }
    }
}
export function getPassengers(data) {
    return {
        type: "GET_PASSENGERS",
        payload: {
            value: data
        }
    }
}
export function getAllOffers(data) {
    return {
        type: "GET_ALL_OFFERS",
        payload: {
            value: data
        }
    }
}
export function getCompletedTrips(data) {
    return {
        type: "GET_COMPLETED",
        payload: {
            value: data
        }
    }
}
export function getCompletedTripsT(data) {
    return {
        type: "GET_COMPLETED_TABLE",
        payload: {
            value: data
        }
    }
}
export function getRejectedOffers(data) {
    return {
        type: "GET_REJECTED_OFFERS",
        payload: {
            value: data
        }
    }
}
export function getRejectedOffersT(data) {
    return {
        type: "GET_REJECTED_OFFERS_TABLE",
        payload: {
            value: data
        }
    }
}
export function getDriverRejectedOffers(data) {
    return {
        type: "GET_REJECTED_OFFERS_D",
        payload: {
            value: data
        }
    }
}
export function getDriverRejectedOffersT(data) {
    return {
        type: "GET_REJECTED_OFFERS_TABLE_D",
        payload: {
            value: data
        }
    }
}
export function getCancelledDriver(data) {
    return {
        type: "GET_CANCELLED_D",
        payload: {
            value: data
        }
    }
}
export function getCancelledDriverT(data) {
    return {
        type: "GET_CANCELLED_DT",
        payload: {
            value: data
        }
    }
}
export function getCancelledPassenger(data) {
    return {
        type: "GET_CANCELLED_P",
        payload: {
            value: data
        }
    }
}
export function getCancelledPassengerT(data) {
    return {
        type: "GET_CANCELLED_PT",
        payload: {
            value: data
        }
    }
}
export function getDriversAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getDrivers', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getDriversBid(response.data.fleet))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function deleteDriverAction(driverId) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/deleteDriver', {
            driverId: driverId
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                Swal.fire({
                    title: 'Uğurlu',
                    text: 'Silindi.',
                    icon: 'success',
                });
            }).
            catch(error => {
                Swal.fire({
                    title: 'Uğursuz',
                    text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                    icon: 'error',
                });
            })
    }
}
export function getInvitesAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getActiveDrivers', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getInvitesBid(response.data.driverList))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getPassengerCountAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getPassengers', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getPassengers(response.data))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getAllOffersAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getAllOffers', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getAllOffers(response.data))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getCompletedTripsAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getCompletedTrips', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getCompletedTrips(response.data))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getCompletedTableAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getCompletedData', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getCompletedTripsT(response.data.offers))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getRejectedOffersAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getRejectedOffers', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getRejectedOffers(response.data))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getDriverRejectedOffersAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getDriverRejectedOffers', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getDriverRejectedOffers(response.data))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getCancelledDriverAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getCancelledByDriver', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getCancelledDriver(response.data))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getCancelledPassengerAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getCancelledByPassenger', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getCancelledPassenger(response.data))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getRejectedTableAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getRejectedData', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getRejectedOffersT(response.data.offers))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getDriverRejectedTableAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getDriverRejectedData', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getDriverRejectedOffersT(response.data.offers))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getCancelledByDriverTableAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getCancelledByDriverData', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getCancelledDriverT(response.data.offers))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getCancelledByPassengerTableAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getCancelledByPassengerData', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                dispatch(getCancelledPassengerT(response.data.offers))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function saveNationalIdAction(img, driverId, url) {
    return function (dispatch) {
        const fd = new FormData();
        fd.append('img', img)
        fd.append('driverId', driverId);
        if (url !== null) {
            fd.append('url', url);
        }

        axios.post('https://api.bid.az/fleet/api/saveNationalId', fd, {
            headers: {
                "x-access-token": localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            }
        }).
            then((response) => {
                Swal.fire({
                    title: 'Uğurlu',
                    text: 'Şəkil elave olundu.',
                    icon: 'success',
                });
            }).
            catch(error => {
                Swal.fire({
                    title: 'Uğursuz',
                    text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                    icon: 'error',
                });
            })
    }
}
export function saveDriverIdAction(img, driverId, url) {
    return function (dispatch) {
        const fd = new FormData();
        fd.append('img', img)
        fd.append('driverId', driverId);
        if (url !== null) {
            fd.append('url', url);
        }

        axios.post('https://api.bid.az/fleet/api/saveDriverId', fd, {
            headers: {
                "x-access-token": localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            }
        }).
            then((response) => {
                Swal.fire({
                    title: 'Uğurlu',
                    text: 'Şəkil elave olundu.',
                    icon: 'success',
                });
            }).
            catch(error => {
                Swal.fire({
                    title: 'Uğursuz',
                    text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                    icon: 'error',
                });
            })
    }
}
export function saveTaxiIdAction(img, driverId, url) {
    return function (dispatch) {
        const fd = new FormData();
        fd.append('img', img)
        fd.append('driverId', driverId);
        if (url !== null) {
            fd.append('url', url);
        }

        axios.post('https://api.bid.az/fleet/api/saveTaxiId', fd, {
            headers: {
                "x-access-token": localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            }
        }).
            then((response) => {
                Swal.fire({
                    title: 'Uğurlu',
                    text: 'Şəkil elave olundu.',
                    icon: 'success',
                });
            }).
            catch(error => {
                Swal.fire({
                    title: 'Uğursuz',
                    text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                    icon: 'error',
                });
            })
    }
}
export function saveImageAction(img, driverId, url) {
    return function (dispatch) {
        const fd = new FormData();
        fd.append('img', img)
        fd.append('driverId', driverId);
        if (url !== null) {
            fd.append('url', url);
        }

        axios.post('https://api.bid.az/fleet/api/saveImage', fd, {
            headers: {
                "x-access-token": localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            }
        }).
            then((response) => {
                Swal.fire({
                    title: 'Uğurlu',
                    text: 'Şəkil elave olundu.',
                    icon: 'success',
                });
            }).
            catch(error => {
                Swal.fire({
                    title: 'Uğursuz',
                    text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                    icon: 'error',
                });
            })
    }
}
export function fleetToBid(data) {
    return {
        type: "FLEET_TO_BID",
        payload: {
            value: data
        }
    }
}
export function getPaymentsBid(data) {
    return {
        type: "GET_PAYMENTS",
        payload: {
            value: data
        }
    }
}
export function getPaymentsAction(start, end) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/getPayments', {
            start: start.split(".")[2] + "-" + start.split(".")[1] + "-" + start.split(".")[0],
            end: end.split(".")[2] + "-" + end.split(".")[1] + "-" + end.split(".")[0]
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                dispatch(getPaymentsBid(response.data.returnModel))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getPaymentsFleetBid(data) {
    return {
        type: "GET_FLEET_PAYMENTS",
        payload: {
            value: data
        }
    }
}
export function getPaymentsFleetAction(month, week) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/getFleetPayment', {
            month: month,
            week: week
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                dispatch(getPaymentsFleetBid(response.data.payment))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function checkPhoneNumberBid(value) {
    return {
        type: "CHECK_PHONE_NUMBER",
        payload: {
            value: value
        }
    }
}
export function checkPhoneNumberAction(phoneNum) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/addDriver', {
            "mobileNum": phoneNum.replaceAll("-", "").replaceAll("(", "").replaceAll(")", ""),
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                dispatch(checkPhoneNumberBid(response.data.driver))
            }).
            catch(error => {
                if (error.response.data.message === "Driver Not found.") {
                    dispatch(checkPhoneNumberBid(true))
                }
            })
    }
}
export function checkActiveDriversBid(value) {
    return {
        type: "GET_ACTIVE_DRIVERS",
        payload: {
            value: value
        }
    }
}
export function checkActiveDriversAction(fleetId) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getActiveDrivers', {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                dispatch(checkActiveDriversBid(response.data.driverList.length))
            }).
            catch(error => {
                dispatch(checkActiveDriversBid('try again'))
            })
    }
}
export function driverRegistrationAction(name, lastname, gender, phoneNum, avtoBrend, startDate, autoModel, avtoBrendColor, carNumber, autoYear, id) {
    return function (dispatch) {
        let formattedDate;
        try {
            const day = startDate?.getDate();
            const month = startDate?.getMonth() + 1;
            const year = startDate?.getFullYear();
            formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        }
        catch (error) {
            console.log(error)
        }

        axios.post('https://api.bid.az/fleet/api/driverAdd', {
            'name': name,
            'lastname': lastname,
            'gender': gender === "Kişi" ? 1 : 0,
            "IDCard": id,
            "birthDate": formattedDate,
            "mobileNum": phoneNum.replaceAll("-", "").replaceAll("(", "").replaceAll(")", ""),
            "maker": avtoBrend,
            "model": autoModel,
            "color": avtoBrendColor,
            "plateNumber": carNumber,
            "year": autoYear
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                Swal.fire({
                    title: 'Uğurlu',
                    text: 'Yeni surucu elave olundu.',
                    icon: 'success',
                });
            }).
            catch(error => {
                Swal.fire({
                    title: 'Uğursuz',
                    text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin. " + error,
                    icon: 'error',
                });
            })

    }
}
export function getSupportBid(data) {
    return {
        type: "GET_SUPPORT_DATA",
        payload: {
            value: data
        }
    }
}
export function getSupportAction(driverId, supportType) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/getSupport', {
            "driverId": driverId,
            "supportType": supportType
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                dispatch(getSupportBid(response.data.supports))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function getMarkersBid(data) {
    return {
        type: "GET_MARKERS",
        payload: {
            value: data
        }
    }
}
export function getDriverIdsAction(mobileNum) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/getDriverIds', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
        }).
            then((response) => {
                axios.post('http://80.241.208.93:5200/api/getMyDrivers', {
                    "driverList": response.data.drivers
                }, {
                    headers: { "x-access-token": localStorage.getItem('token') }
                }).
                    then((response) => {
                        dispatch(getMarkersBid(response.data))
                    }).
                    catch(error => {
                        console.log(error)
                    })
            }).
            catch(error => {
                console.log(error)
            })

    }
}
export function getMarkerDetails(data) {
    return {
        type: "SET_MARKER_DETAILS",
        payload: {
            value: data
        }
    }
}
export function getMarkerDetailsAction(driverId) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/getDriverDetails', {
            "driverId": driverId
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                dispatch(getMarkerDetails(response.data))
            }).
            catch(error => {
                console.log(error)
            })

    }
}
export function searchBid(data) {
    return {
        type: "SET_SEARCH_VALUE",
        payload: {
            value: data
        }
    }
}
export function searchAction(type, text) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/search', {
            "type": type,
            "text": text
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                dispatch(searchBid(response.data.drivers))
            }).
            catch(error => {
                console.log(error)
            })

    }
}
export function getDriverByIdBid(data) {
    return {
        type: "GET_DRIVER_BY_ID",
        payload: {
            value: data
        }
    }
}
export function getDriverByIdAction(id) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/getDriverById', {
            "id": id
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                dispatch(getDriverByIdBid(response.data))
            }).
            catch(error => {
                console.log(error)
            })
    }
}
export function setTariffAction(driverId, baseFare, costPerMin, costPerKm) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/setTariff', {
            "driverId": driverId,
            "baseFare": baseFare,
            "costPerMin": costPerMin,
            "costPerKm": costPerKm
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        title: 'Uğurlu',
                        text: 'Tarif uğurla dəyişdirildi.',
                        icon: 'success',
                    });
                }
            }).
            catch(error => {
                console.log(error)
                Swal.fire({
                    title: 'Uğursuz',
                    text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                    icon: 'error',
                });
            })
    }
}
export function editDriverAction(driverId, cardNo, accountNo, type) {
    return function (dispatch) {
        axios.post('https://api.bid.az/fleet/api/editDriver', {
            "driverId": driverId,
            "cardNo": cardNo,
            "accountNo": accountNo,
            "type": type
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        title: 'Uğurlu',
                        text: 'Uğurla redaktə olundu.',
                        icon: 'success',
                    });
                }
            }).
            catch(error => {
                console.log(error)
                Swal.fire({
                    title: 'Uğursuz',
                    text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                    icon: 'error',
                });
            })
    }
}
export function blockDriverAction(driverId, img, detail) {
    return function (dispatch) {
        const fd = new FormData();
        fd.append('img', img)
        fd.append('driverId', driverId);
        fd.append('detail', detail);
        axios.post('https://api.bid.az/fleet/api/blockDriver', fd, {
            headers: { "x-access-token": localStorage.getItem('token') }
        }).
            then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        title: 'Uğurlu',
                        text: 'Istifadəçi uğurla bloklanmışdır.',
                        icon: 'success',
                    });
                }
            }).
            catch(error => {
                console.log(error)
                Swal.fire({
                    title: 'Uğursuz',
                    text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                    icon: 'error',
                });
            })
    }
}

export function exportExcelAction(startDate, endDate, paymentList) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/exportExcel', {
            params: {
                startDate: startDate,
                endDate: endDate,
                paymentList: paymentList
            },
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${startDate + "-" + endDate}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(error => {
            console.log(error);
            Swal.fire({
                title: 'Error',
                text: "An error occurred. Please try again.",
                icon: 'error',
            });
        });
    }
}
export function exportExcelFleetAction(startDate, endDate, fleetPayment) {
    return function (dispatch) {
        axios.get('https://api.bid.az/fleet/api/exportExcelFleet', {
            params: {
                startDate: startDate,
                endDate: endDate,
                fleetPayment: fleetPayment
            },
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${startDate + "-" + endDate}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(error => {
            console.log(error);
            Swal.fire({
                title: 'Error',
                text: "An error occurred. Please try again.",
                icon: 'error',
            });
        });
    }
}
export function exportPdfAction(box) {
    return function (dispatch) {
        axios.get('https://api.bid.az/createPdf', {
            params: {
                info: box
            },
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${box.name + "-" + box.lastname}.pdf`,
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(error => {
            console.log(error);
            Swal.fire({
                title: 'Error',
                text: "An error occurred. Please try again.",
                icon: 'error',
            });
        });
    }
}